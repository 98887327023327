.css-j5h6pi-MuiPopper-root {
    z-index: 2000 !important;
}

.drawer-bg {
    background-color: #f0f3f5;
}

.drawer-margin {
    margin: 0 33px 0 33px;
}

.border-style {
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 3px #c5c5c5;
}

.border-style:hover {
    transition: 0.2s ease-in-out;
    box-shadow: 0 3px #787878;
}

.filter-button {
    width: auto; 
    height: 35px;
    padding: 0 15px 0 15px;
    font-size: 15px;
    font-weight: bold;
    color: #3a3a3a;
}

.filter-button:hover {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.filter-icon {
    color: #3a3a3a;
}

.variable-container {
    background-color: white;
    height: 120px;
    border-radius: 5px;
    box-shadow: 0 3px #c5c5c5;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
}

.variable-container:hover {
    transition: 0.2s ease-in-out;
    box-shadow: 0 3px #787878;
}

.variable-title {
    font-size: 15px;
    color: #5e5e5e;
}

.variable-value {
    font-size: 30px;
    color: #2a2a2a;
}

.variable-percentage {
    font-size: 15px;
}

/* Modal.js */
.modal-filter {
    background-color: white;
    width: 250px;
    position: absolute;
    border: 1px solid #374151;
    border-radius: 5px;
    padding: 20px 20px 15px 20px;
    box-shadow: 0 3px #c5c5c5;
}

.apply-button {
    width: 80px;
    height: 35px;
    font-size: 16px;
    color: #3a3a3a;
}

.apply-button:hover {
    background-color: rgba(0, 128, 0, 0.5);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}

.reset-button {
    width: 80px;
    height: 35px;
    font-size: 16px;
    color: #3a3a3a;
}

.reset-button:hover {
    background-color: rgba(255, 0, 0, 0.3);
    border-radius: 5px;
    transition: 0.2s ease-in-out;
}