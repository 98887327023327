@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply text-base hover:scale-110 focus:outline-none flex justify-center py-2 rounded font-bold cursor-pointer bg-gray-100 text-gray-700 border duration-200 ease-in-out transition;
  }
  .navbar-btn {
    @apply flex justify-center items-center content-center p-1 transition-colors duration-200 bg-gray-700 rounded-full hover:text-green-600 hover:bg-green-600 focus:outline-none focus:bg-green-700 focus:ring-green-800;
  }

  .toggle-active {
    @apply relative inline-flex items-center h-5 rounded-full w-9 transition-colors focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-green-500;
  }

  .toggle-inactive {
    @apply inline-block w-4 h-4 transform bg-white rounded-full transition-transform;
  }

  .popup-header {
    @apply font-semibold text-base;
  }

  .popup-subheader {
    @apply text-sm text-gray-400;
  }
  .side-panel {
    @apply px-4 my-2 py-3 rounded overflow-hidden shadow-md bg-gray-100 transform-gpu translate-x-full transition duration-500 ease-out;
  }
}
